import moment from 'moment'

export function formatDate(val, f) {
  if (!val) return ""
  return moment(val).format(f)
}

// 货币显示格式
export function formatMoney(num, f) {
  f = f || ','
  num = (num || 0).toString()
  var result = ''
  while (num.length > 3) {
    result = f + num.slice(-3) + result
    num = num.slice(0, num.length - 3)
  }
  if (num) { result = num + result }
  return result
}

// 银行卡格式化
export function formatBankCardNo(val) {
  return formatter(val)
}

let formatter = (value, gap) => {
  gap = gap || 4
  if (!Number.isInteger(gap)) {
    return ''
  }
  if (value === undefined) {
    return ''
  }
  return [...value].reduce((previousValue, currentValue, currentIndex) => {
    if ((currentIndex + 1) % gap === 0) {
      previousValue += `${currentValue} `
    } else {
      previousValue += currentValue
    }
    return previousValue
  }, '').trim()
}
