import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    redirect: '/home',
    children: [
      // 首页
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/Home.vue')
      },
      // 我的
      {
        path: 'mine',
        name: 'Mine',
        component: () => import('../views/Mine.vue')
      },
    ]
  },
  // 个人信息
  {
    path: '/mine/personalInfo',
    name: 'PersonalInfo',
    component: () => import('../views/mine/PersonalInfo.vue')
  },
  // 订单历史
  {
    path: '/mine/orderHistory',
    name: 'OrderHistory',
    component: () => import('../views/mine/OrderHistory.vue')
  },
  // 还款账单
  {
    path: '/mine/repayRecord',
    name: 'RepayRecord',
    component: () => import('../views/mine/RepayRecord.vue')
  },
  // 还款信息
  {
    path: '/mine/repaymentInfo',
    name: 'RepaymentInfo',
    component: () => import('../views/mine/RepaymentInfo.vue')
  },
  // 邀请好友
  {
    path: '/mine/inviteFriends',
    name: 'InviteFriends',
    component: () => import('../views/mine/InviteFriends.vue')
  },
  // 优惠卷
  {
    path: '/mine/discountCoupon',
    name: 'DiscountCoupon',
    component: () => import('../views/mine/DiscountCoupon.vue')
  },
  // 我的推广
  {
    path: '/mine/myPromotion',
    name: 'MyPromotion',
    component: () => import('../views/mine/MyPromotion.vue')
  },
  // 用户（登录 注册 修改密码）
  {
    path: '/user/login',
    name: 'Login',
    component: () => import('../views/user/Login')
  },
  {
    path: '/user/register',
    name: 'Register',
    component: () => import('../views/user/Register')
  },
  {
    path: '/user/password',
    name: 'Password',
    component: () => import('../views/user/Password')
  },
  // 订单申请状态
  {
    path: '/apply/done',
    name: 'Done',
    component: () => import('../views/apply/Done.vue')
  },
  // 实名身份验证
  {
    path: '/step/realnameauth',
    name: 'Realnameauth',
    component: () => import('../views/step/Realnameauth.vue')
  },
  // 工作认证
  {
    path: '/step/jobauth',
    name: 'Jobauth',
    component: () => import('../views/step/Jobauth.vue')
  },
  // 联系人认证
  {
    path: '/step/relationshipauth',
    name: 'Relationshipauth',
    component: () => import('../views/step/Relationshipauth.vue')
  },
  // 银行卡认证
  {
    path: '/step/bankInfo',
    name: 'BankInfo',
    component: () => import('../views/step/BankInfo.vue')
  },
  // 修改银行卡
  {
    path: '/step/modifyBankInfo',
    name: 'ModifyBankInfo',
    component: () => import('../views/step/ModifyBankInfo.vue')
  },
  // 身份认证
  {
    path: '/step/idcardauth',
    name: 'Idcardauth',
    component: () => import('../views/step/Idcardauth.vue')
  },
  // 确认订单
  {
    path: '/step/confirm',
    name: 'Confirm',
    component: () => import('../views/step/Confirm.vue')
  },
  // 代扣
  {
    path: '/apply/deductionAuth',
    name: 'DeductionAuth',
    component: () => import('../views/apply/DeductionAuth.vue')
  },
  // 订单详情
  {
    path: '/apply/loanDetail',
    name: 'LoanDetail',
    component: () => import('../views/apply/LoanDetail.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  if (/\/user\//.test(to.path)) return next()
  if (!store.state.accessToken) return next({path: '/user/login', query: to.query})
  next();
})

export default router
